export default {
    PROFILE: 'Profile',
    CALENDAR: 'Calendar',
    SETTINGS: 'Settings',
    USERNAME: 'Username',
    NAME: 'Name',
    PASSWORD: 'Password',
    LANGUAGE: 'Language',
    PARTICIPANTS: 'Participants',
    RESPONSES: {
        ACCEPT: 'Accept',
        MAYBE: 'Maybe',
        DECLINE: 'Decline',
    },
    LOGIN: 'Login',
    REGISTER: 'Register',
    HELPER: {
        FORGOT_PASSWORD: 'Forgot your password?',
        REGISTER: 'Don\'t have an account? Register',
    },
    ADD_EVENT: 'Add event',
    EDIT_EVENT: 'Edit event',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    START_TIME: 'Start',
    END_TIME: 'End',
    INVITEES: 'Invitees',
    SAVE: 'Save',
    CONFIRM_DELETE_EVENT: 'Delete event?',
    ERRORS: {
        USERNAME_PASSWORD_WRONG: 'Username or password wrong!',
        USERNAME_PASSWORD_EMPTY: 'Username and password must not be empty!',
        EVENT_SAVE: 'Event could not be saved!',
    },
    RECURRENCE: 'Recurring',
    RECURRENCE_ONCE: 'Never',
    RECURRENCE_WEEKLY: 'Weekly',
    ASK_ALL_ONE_DELETE: 'Delete all or this only?',
    ASK_ALL_ONE_SAVE: 'Save for all or this only?',
    ALL_ONE_ONE: 'Only this',
    ALL_ONE_ALL: 'All',
};