import React from 'react';
import {View, Text, StyleSheet} from 'react-native';

function Register({navigation}) {
    return (
        <View>
            <Text>Register</Text>
        </View>
    )
}

export default Register;

const style = StyleSheet.create({})