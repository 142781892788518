export default {
    PROFILE: 'Profil',
    CALENDAR: 'Kalendar',
    SETTINGS: 'Einstellungen',
    USERNAME: 'Nutzername',
    NAME: 'Name',
    PASSWORD: 'Passwort',
    LANGUAGE: 'Sprache',
    PARTICIPANTS: 'Teilnehmer',
    RESPONSES: {
        ACCEPT: 'Annehmen',
        MAYBE: 'Vielleicht',
        DECLINE: 'Ablehnen',
    },
    LOGIN: 'Einloggen',
    REGISTER: 'Registrieren',
    HELPER: {
        FORGOT_PASSWORD: 'Passwort vergessen?',
        REGISTER: 'Noch keinen Account? Registrieren',
    },
    ADD_EVENT: 'Termin hinzufügen',
    EDIT_EVENT: 'Termin bearbeiten',
    TITLE: 'Titel',
    DESCRIPTION: 'Beschreibung',
    START_TIME: 'Start',
    END_TIME: 'Ende',
    INVITEES: 'Teilnehmer',
    SAVE: 'Speichern',
    CONFIRM_DELETE_EVENT: 'Termin wirklich löschen?',
    ERRORS: {
        USERNAME_PASSWORD_WRONG: 'Nutzername oder Passwort falsch!',
        USERNAME_PASSWORD_EMPTY: 'Nutzername und Passwort dürfen nicht leer sein!',
        EVENT_SAVE: 'Termin konnte nicht gespeichert werden!',
    },
    RECURRENCE: 'Wiederholen',
    RECURRENCE_ONCE: 'Nie',
    RECURRENCE_WEEKLY: 'Wöchentlich',
    ASK_ALL_ONE_DELETE: 'Alle oder nur diesen Termin löschen?',
    ASK_ALL_ONE_SAVE: 'Alle oder nur diesen Termin speichern?',
    ALL_ONE_ONE: 'Nur dieser',
    ALL_ONE_ALL: 'Alle',
};